import ProductType from './ProductType.vue'
import ProductTypeCreate from './Create.vue'
import ProductTypeEdit from './Create.vue'

export default [
    {
        path: '/product_type',
        name: 'ProductType',
        component: ProductType
    },
    {
        path: '/product_type/create',
        name: 'ProductTypeCreate',
        component: ProductTypeCreate
    },
    {
        path: '/product_type/edit/:id',
        name: 'ProductTypeEdit',
        component: ProductTypeEdit
    },

]
